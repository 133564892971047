@use "@angular/material" as mat;
@use "breakpoints";
@use "theme-colors" as jrp-colors;

html {
  --jrp-layout-max-width: 840px;
  --jrp-layout-grid-gutter: 16px;
  --jrp-layout-margin: 16px;
  --jrp-header-height: 232px;
  --jrp-header-search-bar-width: 720px;
  --jrp-header-shrunk-height: 64px;

  @each $palette, $shades in jrp-colors.$palettes {
    @each $shade, $color in $shades {
      --mat-sys-#{$palette}-#{$shade}: #{$color};
    }
  }

  --md-extended-color-green-color: light-dark(#226a4c, #8ed5b1);
  --md-extended-color-green-on-color: light-dark(#ffffff, #003824);
  --md-extended-color-green-color-container: light-dark(#aaf2cc, #005236);
  --md-extended-color-green-on-color-container: light-dark(#005236, #aaf2cc);

  --md-extended-color-purple-color: light-dark(#725187, #e0b8f6);
  --md-extended-color-purple-on-color: light-dark(#ffffff, #422356);
  --md-extended-color-purple-color-container: light-dark(#f4daff, #59396e);
  --md-extended-color-purple-on-color-container: light-dark(#2b0b3f, #f4daff);

  color-scheme: light;
  @include mat.theme(
    (
      color: (
        primary: jrp-colors.$primary-palette,
        tertiary: jrp-colors.$tertiary-palette,
      ),
      typography: (
        plain-family: Roboto,
        brand-family: Oswald,
      ),
      density: 0,
    ),
    $overrides: (
      primary: light-dark(#1d3794, #b8c4ff),
      tertiary: light-dark(#ea7b59, #ff8b68)
    )
  );

  @include mat.divider-overrides(
    (
      color: var(--mat-sys-outline-variant),
    )
  );

  @include mat.table-overrides(
    (
      row-item-outline-color: var(--mat-sys-outline-variant),
    )
  );

  @media #{breakpoints.$greater-than-xsmall} {
    --jrp-header-height: 320px;
    --jrp-header-shrunk-height: 88px;
  }

  @media #{breakpoints.$greater-than-medium} {
    --jrp-header-height: 400px;
    --jrp-layout-grid-gutter: 24px;
    --jrp-layout-margin: 24px;
    --jrp-layout-max-width: 1040px;
    --jrp-header-search-bar-width: 920px;
  }
}

.jrp-dark-theme {
  color-scheme: dark;
}

.jrp-tertiary-icon {
  @include mat.icon-overrides(
    (
      color: var(--mat-sys-tertiary),
    )
  );
  @include mat.menu-overrides(
    (
      item-icon-color: var(--mat-sys-tertiary),
    )
  );
}

.jrp-danger-icon {
  @include mat.icon-overrides(
    (
      color: var(--mat-sys-error),
    )
  );
  @include mat.menu-overrides(
    (
      item-icon-color: var(--mat-sys-error),
    )
  );
}

.jrp-danger-button {
  @include mat.button-overrides(
    (
      text-label-text-color: var(--mat-sys-error),
      filled-container-color: var(--mat-sys-error),
      filled-label-text-color: var(--mat-sys-on-error),
      filled-ripple-color: color-mix(
          in srgb,
          var(--mat-sys-on-error) 12%,
          transparent
        ),
      text-state-layer-color: var(--mat-sys-error),
      text-ripple-color: color-mix(
          in srgb,
          var(--mat-sys-error) 12%,
          transparent
        ),
    )
  );
}

.jrp-danger-container-button {
  @include mat.button-overrides(
    (
      text-label-text-color: var(--mat-sys-error),
      outlined-label-text-color: var(--mat-sys-error),
      outlined-state-layer-color: var(--mat-sys-error),
      outlined-ripple-color: color-mix(
          in srgb,
          var(--mat-sys-on-error-container) 12%,
          transparent
        ),
      filled-container-color: var(--mat-sys-error-container),
      filled-label-text-color: var(--mat-sys-on-error-container),
      filled-ripple-color: color-mix(
          in srgb,
          var(--mat-sys-on-error-container) 12%,
          transparent
        ),
      text-state-layer-color: var(--mat-sys-error-container),
      text-ripple-color: color-mix(
          in srgb,
          var(--mat-sys-error-container) 12%,
          transparent
        ),
    )
  );
}

.jrp-danger-icon-button {
  @include mat.icon-button-overrides(
    (
      icon-color: var(--mat-sys-error),
      ripple-color: color-mix(in srgb, var(--mat-sys-error) 12%, transparent),
    )
  );
}

.jrp-success-snack-bar {
  @include mat.snack-bar-overrides(
    (
      container-color: var(--md-extended-color-green-color),
      supporting-text-color: var(--md-extended-color-green-on-color),
      button-color: var(--md-extended-color-green-on-color),
    )
  );
}

.jrp-error-snack-bar {
  @include mat.snack-bar-overrides(
    (
      container-color: var(--mat-sys-error),
      supporting-text-color: var(--mat-sys-on-error),
      button-color: var(--mat-sys-on-error),
    )
  );
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--mat-sys-background);
  color: var(--mat-sys-on-background);
}

a {
  text-decoration: none;

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
}

strong,
b {
  font-weight: var(--mat-sys-label-large-weight-prominent);
}

em {
  font-weight: var(--mat-sys-label-large-weight-prominent);
  font-style: normal;
}

::selection {
  background-color: var(--mat-sys-tertiary);
  color: var(--mat-sys-on-tertiary);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;

  &-track {
    background: transparent;
    cursor: pointer;
  }

  &-thumb {
    border-radius: 10px;
    transition: background-color 0.3s ease;
    background-color: light-dark(
      var(--mat-sys-neutral-80),
      var(--mat-sys-neutral-40)
    );

    &:hover {
      background-color: light-dark(
        var(--mat-sys-neutral-70),
        var(--mat-sys-neutral-50)
      );
    }
  }
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" -25,
    "opsz" 48;
}

.jrp-logo {
  .colorful {
    fill: var(--mat-sys-tertiary);
  }
}

.jrp-radio-group-label {
  display: block;
  margin-bottom: 8px;
}

.jrp-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color-mix(
    in oklab,
    var(--mat-sys-primary) 12%,
    transparent
  );
}

.jrp-radio-group-label {
  color: var(--mat-sys-on-surface-variant);
  font: var(--mat-sys-label-large);
  letter-spacing: var(--mat-sys-label-large-tracking);
}

.jrp-full-screen-dialog {
  max-height: 80vh;

  @media #{breakpoints.$lower-than-medium} {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    @include mat.dialog-overrides(
      (
        container-shape: 0,
      )
    );

    mat-dialog-content {
      max-height: none;
    }
  }
}

.jrp-header-shrunk .jrp-search-bar {
  border: none;
  background: var(--mat-sys-surface-container-high);
}

.jrp-similar-court-decision-menu {
  &-panel {
    width: 280px;
  }
  &-title {
    margin: 8px 12px 16px;
    color: var(--mat-sys-secondary);
    font: var(--mat-sys-title-large);
    letter-spacing: var(--mat-sys-title-large-tracking);
  }
}

.jrp-timeline {
  .vis-timeline,
  .vis-panel.vis-center,
  .vis-panel.vis-left,
  .vis-panel.vis-right,
  .vis-panel.vis-top,
  .vis-panel.vis-bottom,
  .vis-time-axis .vis-grid.vis-minor,
  .vis-time-axis .vis-grid.vis-major {
    border-color: var(--mat-sys-outline-variant);
  }

  .vis-time-axis .vis-text {
    padding: 8px;
    font: var(--mat-sys-label-large);
    letter-spacing: var(--mat-sys-label-large-tracking);

    &.vis-major,
    &[class*="vis-year"] {
      font: var(--mat-sys-title-small);
      letter-spacing: var(--mat-sys-title-small-tracking);
    }
  }

  .vis-timeline {
    border-radius: var(--mat-sys-corner-large);
    background: var(--mat-sys-surface);

    .vis-item {
      border-color: var(--mat-sys-primary);

      .vis-item-content {
        background: var(--mat-sys-secondary-container);
        color: var(--mat-sys-on-secondary-container);
        font: var(--mat-sys-label-large);
        letter-spacing: var(--mat-sys-label-large-tracking);

        &.current {
          background: var(--mat-sys-primary);
          color: var(--mat-sys-on-primary);
        }
      }
    }
  }

  .vis-item.vis-box {
    border-radius: var(--mat-sys-corner-small);
    overflow: hidden;

    .vis-item-content {
      white-space: normal;
      padding: 12px;
      cursor: pointer;
    }
  }

  .vis-time-axis .vis-text {
    color: var(--mat-sys-on-surface);

    &.vis-minor:not([class*="vis-year"]) {
      color: var(--mat-sys-on-surface-variant);
    }
  }

  .vis-current-time {
    background-color: var(--mat-sys-tertiary);
  }
}

.jrp-card-primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition:
      opacity 15ms linear,
      background-color 15ms linear;
    background: var(--mat-sys-secondary);
  }

  &:focus::before {
    opacity: 0.06;
  }

  &:hover::before {
    opacity: 0.04;
  }
}

.jrp-activity-option {
  @include mat.core-overrides(
    (
      option-label-text-font: var(--mat-sys-label-large-font),
      option-label-text-line-height: var(--mat-sys-label-large-line-height),
      option-label-text-size: var(--mat-sys-label-large-size),
      option-label-text-tracking: var(--mat-sys-label-large-tracking),
      option-label-text-weight: var(--mat-sys-label-large-weight),
    )
  );

  &-code {
    color: var(--mat-sys-primary);
    font: var(--mat-sys-body-small);
    letter-spacing: var(--mat-sys-body-small-tracking);
    margin-block: 2px;
  }
}

// https://github.com/angular/components/issues/26584
.mdc-evolution-chip-set .mat-mdc-standard-chip {
  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }
}

// https://github.com/angular/components/issues/26701
mat-option.mat-mdc-option {
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
}

// https://github.com/angular/components/issues/10744
.mat-expansion-panel-header {
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    display: block;
    align-items: initial;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-basis: 5%;
  }
}
